import React from "react";
import { SITE_TITLE_APPEND } from "../constants";
import Seo from "../components/seo";
import Layout, { PageContents } from "../components/layout";
import Header from "../components/header";
import whatsapp from "../images/w1.png";
// markup
const ContactPage = () => {
  return (
    <Layout>
      <main>
        <Seo title={`Contact ${SITE_TITLE_APPEND}`} />
        <Header />

        <PageContents title="Contact">
          <div className="custom-text">
            <address>
              Dravida Entertainments
              <br />
              #109 B, Near Church Chapel,
              <br />
              Puthuvassery, Nedumbassery P.O,
              <br />
              Ernakulam, Kerala,
              <br />
              683585
              <br />
              <p className="mt-3">
                Email:{" "}
                <a
                  title="Email Dravida Entertainments"
                  href="mailto:info@dravida.co.in"
                  className="text-decoration-none"
                >
                  info@dravida.co.in
                </a>
              </p>
            </address>
            <div>
              <a href="tel: +918884602711" className="text-decoration-none">
                <img src={whatsapp} width="20" alt="" /> +91 88846 02711
              </a>
            </div>
          </div>
        </PageContents>
      </main>
    </Layout>
  );
};

export default ContactPage;
